<template>
<div>
  <div class="access">
    <img class="parking" src="../assets/vrx2.webp">
    <h2 class="name_h2">ブリザック VRX2</h2>

    <p class="access_p">
    ブリザックは、北海道/北東北主要5都市一般ドライバー装着率21年連続ナンバーワンの実績をもつタイヤです。<br>
    VRX2はブリヂストン独自のアクティブ発泡ゴム2により氷上の水の膜を取り除き、しっかり止まるを実現します。<br>
    小山・御殿場市内の街乗り中心の方におすすめです。
    </p>

    <div class="square-01">
    </div>

    <h3 class="recom_h3">推奨車種</h3>
    <p class="recom">セダン / コンパクト / 軽 / ミニバン /一部SUV</p>


    <CarouselsWinter/>

    <p class="about_p">ACCESS</p>
    <h1>勝間田タイヤ</h1>
    <p class="address_p">静岡県御殿場市茱萸沢241-1</p>
    <a href="https://goo.gl/maps/oabDqVGbjJQn65yk8">Google Maps</a>
  </div>
  <div>
    <Footer/>
  </div>
</div>
</template>

<script>
import CarouselsWinter from '@/components/CarouselsWinter.vue'
import Footer from '@/components/Footer.vue'
export default {
  name: 'HomeView',
  components: {
    CarouselsWinter,
    Footer
  }
}
</script>

<style scoped>
.recom_h3{
  text-align: left;
}
.recom{
  padding-bottom: 3rem;
  font-size: 0.95rem;
  font-weight: 500;
  line-height: 1.6rem;
}
.recom_list{
  padding-bottom: 1rem;
  font-size: 0.95rem;
  font-weight: 500;
  line-height: 1.6rem;
}
.recom_list2{
  padding-bottom: 6rem;
  font-size: 0.95rem;
  font-weight: 500;
  line-height: 1.6rem;
}
.access{
  padding-top: 3rem;
  padding-left: 2rem;
  padding-right: 2rem;
  padding-bottom: 6rem;
}
.access h1{
  margin-top: 0.8rem;
  padding-bottom: 0.5rem;
  text-align:left;
  font-size: 1.9rem;
}
.access p{
  text-align:left;
}
.access_p{
  padding-bottom: 2rem;
  font-size: 0.95rem;
  font-weight: 500;
  line-height: 1.7rem;
}

.access a {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 30px;
  width: 160px;
  height: 50px;
  color: #333;
  font-size: 13px;
  font-weight: 700;
  text-decoration: none;
  background-color: #9099a2;
  border-radius: 40px;
  margin-top: 30px;
}
.access a::after {
  content: '';
  width: 10px;
  height: 10px;
  border-top: 2px solid #d5d5d5;
  border-right: 2px solid #d5d5d5;
  transform: rotate(45deg);
}

.name_h2{
  padding-top: 1rem;
  font-size: 1.5rem;
  text-align: left;
  padding-bottom: 2rem;
}

.about_p{
  margin-top: -4rem;
  font-size: 0.95rem;
  font-weight: 500;
  line-height: 1.6rem;
  padding-top: 4rem;
}
.address_p{
  font-weight: 600;
  font-size: 0.8rem;
}

nav {
  margin-top: -7rem;
  padding-bottom: 6rem;
  padding-left: 2rem;
  padding-right: 2rem;
  font-size: 0.8rem;
  text-align:left;
  line-height: 1.6rem;
}

nav a {
  font-weight: bold;
  text-decoration: none;
  color: #2c3e50;
}
nav a.router-link-exact-active {
  color: #42b983;
}
nav h3{
  font-size: 1.5rem;
}



.access img {
  width: 100%;
  padding-bottom: 2rem;
  padding-top: 1rem;
}

@media (max-width: 640px)
.page .moretodiscover .square-01 {
    width: 67.5vw;
    height: calc(100% - 25vw);
    position: absolute;
    top: 25vw;
    right: 0;
    background-color: #dae4e8;
}

</style>
